.app {
  text-align: center;
  font-family: 'Gilroy', sans-serif;
}

.anticon-delete {
  color: white !important;
}

.screen-loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff42;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff42;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}

.ant-spin-text {
  font-size: 25px !important;
  color: #ffffff;
  font-weight: 500;
  text-shadow: none !important;
  display: flex !important;
  justify-content: center !important;
}

.ant-modal-root .ant-modal-wrap {
  z-index: 100;
}

.ant-modal-root .ant-modal-mask {
  z-index: 90;
}

/* .ant-popover > .ant-popover-content > .ant-popover-inner {
  background-color: #0d1122 !important;
} */

/* .ant-popconfirm-message-title {
  color: antiquewhite !important;
} */

.ant-popconfirm-inner-content > .ant-popconfirm-buttons > .ant-btn-default {
  background-color: transparent !important;
  border-radius: 6px;
  border: 1px solid #7000ff;
}
/* .ant-popconfirm-inner-content
  > .ant-popconfirm-buttons
  > .ant-btn-default
  > span {
  color: #ffffff !important;
} */
.ant-popconfirm-inner-content > .ant-popconfirm-buttons > .ant-btn-primary {
  background-color: #7000ff !important;
  border-radius: 6px;
}
/* .ant-popconfirm-inner-content
  > .ant-popconfirm-buttons
  > .ant-btn-primary
  > span {
  color: #00113e !important;
} */

.layout-main {
  min-height: 100vh;
  height: 100%;
}

.para {
  font-family: 'Gilroy', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select > .ant-select-clear {
  top: 40% !important;
}

.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #dd4d4f !important;
}
.ant-input-affix-wrapper {
  border-color: #343444 !important;
}
.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper > textarea.ant-input {
  background: rgb(19, 15, 64) !important;
  color: white !important;
}
.ant-input-textarea-show-count .ant-input-data-count {
  color: white !important;
}
.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
  > textarea.ant-input::placeholder {
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8a8aa0;
}

.padbottom4 {
  padding-bottom: 1rem;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

.ql-toolbar.ql-snow {
  border: 1px solid #343444 !important;
  text-align: left;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ql-editor {
  color: white !important;
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #ffffff36;
}
.ql-snow .ql-picker {
  color: #8a8aa0;
}
.ql-snow .ql-stroke {
  stroke: #8a8aa0;
}
.ql-container.ql-snow {
  border: 1px solid #343444 !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ql-editor {
  min-height: 120px !important;
}
.ql-editor.ql-blank::before {
  color: #8a8aa0 !important;
  font-style: normal !important;
}
